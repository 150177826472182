import Introduction from 'components/Introduction';
import InfoBody from '../elements/InfoBody';
import Media from 'components/Media';
import classNames from 'classnames';

const Info3a = ({
    settings,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} size={'sm'} />
                {media && (
                    <div className={'info__media'}>
                        <Media media={media} />
                    </div>
                )}
                <InfoBody
                    description={description}
                    buttons={buttons}
                    footnotes={footnotes}
                />
            </div>
        </section>
    );
};

export default Info3a;
