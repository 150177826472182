import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import Feature from '../elements/Feature';
import classNames from 'classnames';
import IntroButton from 'components/IntroButton';

const Features4a = ({
    settings,
    center_items,
    elevation,
    introduction,
    button,
    media,
    features,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section features', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                {media && (
                    <div className={'features__media'}>
                        <Media media={media} />
                    </div>
                )}

                <div className="grid-md-1 row-gap-md-12 features__items">
                    {features.map((feature, index) => {
                        return (
                            <Feature
                                key={index}
                                center_items={center_items}
                                elevation={elevation}
                                variant={2}
                                layout={'intrinsic'}
                                {...feature}
                            />
                        );
                    })}
                </div>
                {footnotes?.value && <Footnotes footnotes={footnotes} />}
            </div>
        </section>
    );
};

export default Features4a;
