import Introduction from 'components/Introduction';
import Tabs from 'components/Tabs';
import classNames from 'classnames';

const Tabs1a = ({ settings, introduction, tabs, attr }) => {
   return (
      <section
         className={classNames('section', {
            ...settings
         })}
         {...attr}
      >
         <div className="container">
            <Introduction {...introduction} />
            <Tabs
               tabs={tabs}
               iconStyle={'top'}
               grow
               icons
            />
         </div>
      </section>
   );
};

export default Tabs1a;
