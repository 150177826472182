import IntroButton from 'components/IntroButton';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import Product from '../elements/Product';

const Products1a = ({ settings, introduction, button, products, footnotes, attr }) => {
	return (
		<section
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<IntroButton introduction={introduction} button={button} />
				{products?.map((product, index) => {
					return <Product key={index} {...product} />;
				})}
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Products1a;
