import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import Tile from 'components/Tile';
import classNames from 'classnames';

const Resources3a = ({ settings, introduction, footnotes, resources, attr }) => {
	return (
		<section
			
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<Introduction {...introduction} />
				<div className={'grid-lg-3 grid-md-2'}>
					{resources.map((resource, index) => {
						return (
							<Tile
								key={index}
								variant={1}
								{...resource}
							/>
						);
					})}
				</div>
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Resources3a;
