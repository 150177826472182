import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import Feature from '../elements/Feature';
import classNames from 'classnames';
import IntroButton from 'components/IntroButton';

const Features1a = ({
	settings,
	center_items,
	elevation,
	offset_media,
	introduction,
	button,
	media,
	features,
	footnotes,
	attr,
}) => {
	return (
		<section	
			className={classNames('section features', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<IntroButton introduction={introduction} button={button} />
				{media && (
					<div className='features__media'>
						<Media media={media} />
					</div>
				)}

				<div
					className={classNames('grid-md-2 features__items', {
						'row-gap-md-12': !offset_media,
						'row-gap-20 row-gap-md-24 mt-22': offset_media,
					})}
				>
					{features?.map((feature, index) => {
						return (
							<Feature
								key={index}
								center_items={center_items}
								elevation={elevation}
								offset_media={offset_media}
								variant={1}
								{...feature}
							/>
						);
					})}
				</div>
				{footnotes?.value && <Footnotes footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Features1a;
