import IntroButton from 'components/IntroButton';
import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';


const Statistics2a = ({ settings, introduction, media, statistics, button, footnotes, attr }) => {
	return (
		<section
			
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<IntroButton introduction={introduction} button={button} />
				<div className={'grid-lg-12 row-gap-10 row-gap-lg-0'}>
					<div className='col-lg-7 order-2 order-lg-1'>
						{statistics.map((statistic, index) => {
							const { id, codename, title, description, stat } = statistic
							return (
								<article
									key={index}
									className='statistic statistic--1'
									data-kontent-item-id={id}
									data-kontent-element-codename={codename}
									data-kontent-add-button={!!codename}
									data-kontent-add-button-render-position='bottom'
									data-kontent-add-button-insert-position='after'
								>
									{stat && <div className='statistic__stat'>{stat}</div>}
									<div className='statistic__content'>
										{title?.value && <RichText className='statistic__title' data={title} />}
										{description?.value && (
											<RichText className='statistic__description' data={description} />
										)}
									</div>
								</article>
							);
						})}
					</div>
					{media && (
						<div className='col-start-lg-9 col-end-lg-13 order-1 order-lg-2'>
							<Media className={'fluid'} media={media} />
						</div>
					)}
				</div>

				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Statistics2a;
