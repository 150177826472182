import FormSelector from '../elements/FormSelector';
import Introduction from 'components/Introduction';
import useForm from 'lib/hooks/useForm';
import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';
import { useRef } from 'react';
import Results from '../elements/Results';
import MarketoScript from '../elements/MarketoScript';
import Form from '../elements/Form';

const Form3a = ({
    settings,
    introduction,
    description,
    media,
    form,
    form_element,
    language,
    fields,
    footnotes,
    thank_you_message,
    child_theme,
    attr,
}) => {
    const ref = useRef();
    const { success, error, isPending, results, handleSubmit } = useForm({
        form,
        ref,
        form_element,
    });
    return (
        <>
            <MarketoScript />

            <section
                className={classNames('section form__wrapper', {
                    ...settings,
                })}
                {...attr}
                ref={ref}
                data-form={form}
            >
                <div className="container">
                    <div className="grid-lg-2 gap-12 gap-lg-7">
                        <div>
                            <Media wrapperClassName="mb-8" media={media} />
                            <RichText data={description} />
                        </div>
                        <div
                            className={classNames('p-6 p-lg-12', {
                                [`${child_theme}`]: child_theme,
                            })}
                        >
                            {!success && (
                                <>
                                    <Introduction {...introduction} size="sm" />
                                    {form_element ? (
                                        <Form
                                            form={form_element}
                                            isPending={isPending}
                                            submit={handleSubmit}
                                        />
                                    ) : (
                                        <FormSelector
                                            form={form}
                                            handleSubmit={handleSubmit}
                                            isPending={isPending}
                                            fields={fields}
                                            language={language}
                                        />
                                    )}
                                    {footnotes?.value && (
                                        <Footnotes
                                            className="mt-10"
                                            footnotes={footnotes}
                                        />
                                    )}
                                    {error && (
                                        <RichText
                                            className="form__error"
                                            data={{ value: error }}
                                        />
                                    )}
                                </>
                            )}
                            {success && (
                                <Results
                                    success={success}
                                    form_element={form_element}
                                    thank_you_message={thank_you_message}
                                    results={results}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Form3a;
