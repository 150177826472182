import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';

const Table1a = ({ settings, introduction, table, footnotes, disable_header, disable_multiline_header, text_align_for_header, attr }) => {
	return (
		<section
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<Introduction {...introduction} size='xs' />
				<RichText
					className={classNames('table', {
						'table--disable-header': disable_header,
						'table--disable-multiline-header': disable_multiline_header,
						'table--header-align-center': text_align_for_header === 'center',
						'table--header-align-right': text_align_for_header === 'right',
					})}
					data={table}
				/>
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Table1a;
