import FormSelector from '../elements/FormSelector';
import Introduction from 'components/Introduction';
import Phone from 'blocks/Contact/elements/Phone';
import Email from 'blocks/Contact/elements/Email';
import useForm from 'lib/hooks/useForm';
import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';
import { useRef } from 'react';
import Results from '../elements/Results';
import MarketoScript from '../elements/MarketoScript';
import Form from '../elements/Form';

const Form5a = ({
    settings,
    introduction,
    description,
    media,
    form,
    form_element,
    footnotes,
    thank_you_message,
    attr,
}) => {
    const ref = useRef();
    const { success, error, isPending, results, handleSubmit } = useForm({
        form,
        ref,
        form_element,
    });
    return (
        <>
            <MarketoScript />
            <section
                className={classNames('section form__wrapper', {
                    ...settings,
                })}
                {...attr}
                ref={ref}
                data-form={form}
            >
                <div className="container">
                    <div
                        className={classNames('formcard', {
                            [`bg-${form_element.theme}`]: form_element.theme,
                            'formcard--centered': !media,
                        })}
                    >
                        <div
                            className={classNames('formcard__content', {
                                'formcard__content--centered': !media,
                            })}
                        >
                            {!success && (
                                <>
                                    <div
                                        className={classNames({
                                            'text-center': !media,
                                        })}
                                    >
                                        <Introduction
                                            {...introduction}
                                            size="sm"
                                        />
                                        <RichText
                                            className="formcard__description"
                                            data={description}
                                        />
                                    </div>
                                    <Form
                                        form={form_element}
                                        isPending={isPending}
                                        submit={handleSubmit}
                                    />
                                    {footnotes?.value && (
                                        <Footnotes
                                            className="mt-10"
                                            footnotes={footnotes}
                                        />
                                    )}
                                    {error && (
                                        <RichText
                                            className="form__error"
                                            data={{ value: error }}
                                        />
                                    )}
                                </>
                            )}
                            {success && (
                                <Results
                                    success={success}
                                    form_element={form_element}
                                    thank_you_message={thank_you_message}
                                    results={results}
                                />
                            )}
                        </div>
                        {media && (
                            <Media
                                wrapperClassName={'formcard__media'}
                                layout="fill"
                                objectFit="cover"
                                media={media}
                                disableElevation
                                disableCaption
                            />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Form5a;
