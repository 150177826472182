import Introduction from 'components/Introduction';
import InfoBody from '../elements/InfoBody';
import Media from 'components/Media';
import classNames from 'classnames';

const Info2d = ({
    settings,
    horizontal_spacing,
    vertical_alignment,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <div
                    className={classNames('grid-md-12', {
                        'info--vertical': vertical_alignment,
                    })}
                >
                    <div
                        className={classNames({
                            'col-md-6':
                                horizontal_spacing === 'default' && media,
                            'col-md-7': horizontal_spacing === 'tight' && media,
                            'col-md-9': !media,
                        })}
                    >
                        <Introduction {...introduction} size={'sm'} />
                        {media && (
                            <div className="info__media d-block d-md-none">
                                <Media media={media} />
                            </div>
                        )}
                        <InfoBody
                            description={description}
                            buttons={buttons}
                            footnotes={footnotes}
                        />
                    </div>
                    {media && (
                        <div
                            className={
                                'd-none d-md-block col-start-md-8 col-end-md-13'
                            }
                        >
                            <Media media={media} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Info2d;
