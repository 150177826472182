import Card from 'components/Card';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import IntroButton from 'components/IntroButton';

const Products2a = ({ settings, introduction, button, products, footnotes, attr }) => {
	return (
		<section
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<IntroButton introduction={introduction} button={button} />
				<div className='grid-md-2 row-gap-md-8'>
					{products.map((product, index) => {
						return (
							<Card
								key={index}
								variant={1}
								{...product}
							/>
						);
					})}
				</div>
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Products2a;
