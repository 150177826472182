import Introduction from 'components/Introduction';
import InfoBody from '../elements/InfoBody';
import Media from 'components/Media';
import classNames from 'classnames';

const Info2b = ({
    settings,
    horizontal_spacing,
    vertical_alignment,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} />
                {(media ||
                    description?.value ||
                    buttons ||
                    footnotes?.value) && (
                    <div
                        className={classNames('grid-md-12 gap-8 gap-md-7', {
                            'info--vertical': vertical_alignment,
                        })}
                    >
                        {media && (
                            <div
                                className={
                                    'col-start-md-6 col-end-md-13 order-1 order-md-2'
                                }
                            >
                                <Media media={media} />
                            </div>
                        )}
                        <div
                            className={classNames({
                                'order-2 order-md-1': media,
                                'col-md-4':
                                    horizontal_spacing === 'default' && media,
                                'col-md-5':
                                    horizontal_spacing === 'tight' && media,
                                'col-md-9': !media,
                            })}
                        >
                            <InfoBody
                                description={description}
                                buttons={buttons}
                                footnotes={footnotes}
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Info2b;
