import IntroButton from 'components/IntroButton';
import Footnotes from 'components/Footnotes';
import Media from 'components/Media';
import RichText from 'components/RichText';
import classNames from 'classnames';

const Statistics4b = ({
   settings,
   introduction,
   statistics,
   button,
   footnotes,
   attr,
}) => {
   return (
      <section
         
         className={classNames('section', {
            ...settings
         })}
         {...attr}
      >
         <div className="container">
            <IntroButton introduction={introduction} button={button} />
            <div className={'grid-md-2 grid-lg-3'}>
               {statistics.map((statistic, index) => {
                  const { id, codename, title, description, stat, media } =
                     statistic
                  return (
                     <article
                        key={index}
                        className="statistic statistic--3"
                        data-kontent-item-id={id}
                        data-kontent-element-codename={codename}
                        data-kontent-add-button={!!codename}
                        data-kontent-add-button-render-position="right"
                        data-kontent-add-button-insert-position="after"
                     >
                        <div className="statistic__head">
                           {media && (
                              <Media
                                 media={media}
                                 className="statistic__icon"
                              />
                           )}
                           <div className="statistic__content">
                              {stat && (
                                 <div className="statistic__stat mb-2">
                                    {stat}
                                 </div>
                              )}
                              {title && (
                                 <RichText
                                    className="statistic__title"
                                    data={title}
                                 />
                              )}
                           </div>
                        </div>
                        {description && (
                           <RichText
                              className="statistic__description"
                              data={description}
                           />
                        )}
                     </article>
                  );
               })}
            </div>

            {footnotes?.value && <Footnotes className="mt-14" footnotes={footnotes} />}
         </div>
      </section>
   );
};

export default Statistics4b;
