import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import ResourceButtons from '../elements/ResourceButtons';

const Resources2a = ({ settings, introduction, footnotes, resources, attr }) => {
	return (
		<section
			
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<Introduction {...introduction} />

				<div className='grid-md-2'>
					{resources.map((resource, index) => {
						return <ResourceButtons key={index} resource={resource} />;
					})}
				</div>
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Resources2a;
