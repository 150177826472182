import Location from '../elements/Location';
import classNames from 'classnames';

const Locations1b = ({ settings, region_title, location_groups, attr }) => {
   return (
      <section
         className={classNames('section', {
            ...settings
         })}
         {...attr}
      >
         <div className="container">
            {region_title && <h3 className="mb-md-12">{region_title}</h3>}
            {location_groups &&
               location_groups.map((group, index) => {
                  const { title, locations } = group;
                  return (
                     <div className="last-child-mb-12" key={index}>
                        {title && <h4 className="mb-4">{title}</h4>}
                        <div className="grid-md-2 grid-lg-4">
                           {locations?.map((location, index) => {
                              return (
                                 <Location key={index} icon {...location}/>
                              );
                           })}
                        </div>
                     </div>
                  );
               })}
         </div>
      </section>
   );
};

export default Locations1b;
