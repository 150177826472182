import FormSelector from '../elements/FormSelector';
import Introduction from 'components/Introduction';
import useForm from 'lib/hooks/useForm';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import { useRef } from 'react';
import RichText from 'components/RichText';
import MarketoScript from '../elements/MarketoScript';
import Form from '../elements/Form';
import Results from '../elements/Results';

const Form4a = ({
    settings,
    introduction,
    form,
    form_element,
    language,
    fields,
    footnotes,
    thank_you_message,
    attr,
}) => {
    const ref = useRef();
    const { success, error, isPending, results, handleSubmit } = useForm({
        form,
        ref,
        form_element,
    });
    return (
        <>
            <MarketoScript />
            <section
                className={classNames('section form__wrapper', {
                    ...settings,
                })}
                {...attr}
                ref={ref}
                data-form={form}
            >
                <div className="container">
                    <Introduction {...introduction} />
                    <div className="grid-md-12">
                        <div className="col-md-12 bg-white p-6 p-lg-12 elevation-closest">
                            {!success && (
                                <>
                                    {form_element ? (
                                        <Form
                                            form={form_element}
                                            isPending={isPending}
                                            submit={handleSubmit}
                                        />
                                    ) : (
                                        <FormSelector
                                            form={form}
                                            handleSubmit={handleSubmit}
                                            isPending={isPending}
                                            fields={fields}
                                            language={language}
                                        />
                                    )}
                                </>
                            )}
                            {success && (
                                <Results
                                    success={success}
                                    form_element={form_element}
                                    thank_you_message={thank_you_message}
                                    results={results}
                                />
                            )}

                            {footnotes?.value && (
                                <Footnotes
                                    className="mt-10"
                                    footnotes={footnotes}
                                />
                            )}

                            {error && (
                                <RichText
                                    className="form__error"
                                    data={{ value: error }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Form4a;
