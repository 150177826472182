import Media from 'components/Media';

const Tout6a = ({ media, meta }) => {
    return (
        <div {...meta}>
            {media && <Media media={media} disableCaption disableElevation />}
        </div>
    );
};

export default Tout6a;
