import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import ResourceSelector from '../elements/ResourceSelector';

const Resources1a = ({ settings, introduction, footnotes, resources, attr }) => {
	return (
		<section
			
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<Introduction {...introduction} />
				<div className='grid-md-2 row-gap-md-16 align-start'>
					{resources.map((resource, index) => {
						return <ResourceSelector key={index} resource={resource} />;
					})}
				</div>
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Resources1a;
